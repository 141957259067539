import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
// import { ContactButton } from '../components/ContactForm';

import './index.css'

const LINKS = [
  // { name: 'Blog', type: 'blog', url: 'http://doughamlin.com/blog' },
  { name: 'Instagram', type: 'instagram', url: 'http://instagram.com/doughamlin/' },
  { name: "Articles I'm Reading", type: 'pocket', url: 'https://getpocket.com/@doughamlin' },
]

class SiteLink extends React.Component {
  render() {
    return (
      <li className={'site-link site-link-' + this.props.type}>
        <a href={this.props.url} title={this.props.name}>
          <span>{this.props.name}</span>
        </a>
      </li>
    )
  }
}

class SiteLinks extends React.Component {
  render() {
    const links = this.props.links.map((link, index) => {
      return <SiteLink key={index} name={link.name} type={link.type} url={link.url} />
    })

    return (
      <nav className="site-links">
        {/* <ContactButton /> */}
        <ul>{links}</ul>
      </nav>
    )
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="" />
    <div className="App">
      <SiteLinks links={LINKS} />
    </div>
  </Layout>
)

export default IndexPage
